@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');

body {
  margin: 0;
  font-family: Sen !important;
  scroll-behavior: smooth !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui, .header, .item {
  font-family:  Sen !important;
}

.ui .statistic .value{
  font-family:  Sen !important;

}

.active-student{
  border-right:2px solid orange;
}

.in-active{
  border-right:2px solid black;
}

.ui .label {
  font-family:  Sen !important;
}

.ui .card .header{
  font-family: Sen !important;
}

.senize{
  font-family: Sen !important;
}
