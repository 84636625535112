.fit-column
{
  display:grid;
  grid-template-columns: minmax(1);
  column-gap: 0px !important;
}

.open-grids{
  margin-left:5px !important;
}

.subject{
  font-weight: 800;
}

.loader-div{
  display: flex;
  align-items: center;
  width:100%;
  height: 50vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color:rgba(90, 104, 126, 0.4);
}

.cLabel{
  display: flex !important;
  flex:1 !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  align-content: center !important;
  padding:20px;
}

.wall{
  min-height: 100vh !important;
}

.school-name{
  border-right:1px solid inherit !important; 
  padding-left:5px !important;
}



.sign-session{
  color:white !important;
 
}

/* Updated UI*/

.right-side{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(70deg, rgba(10, 40, 50, 0.5),  rgba(10, 40, 50, 0.5)), url(./assets/DSC_0123.jpg) !important;
  background-repeat: no-repeat !important;
  background-size:cover !important; 
  background-position: center !important;

}

.left-side{
  
  display:flex !important;
  min-height: 100vh !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  background-image:url(./assets/login-back.jpg);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background-color: gray;
  background-blend-mode: multiply;
}


.fcontainer{
  display:flex; 
  flex-direction:column;
  align-items: stretch;
  width: 350px !important;
}

.initials{
  display:flex;
  height:100vh;
  align-items: center;
  justify-content: space-around;
  align-self: center !important;
  flex-direction:row !important;
}

.initials > div > .tt {
  padding: 5px;
  font-weight: bolder !important;
  font-size:170px !important;
}

.nowrap{
  white-space: nowrap !important;
}


.hol{
  display: none !important;
}

@media screen and (max-width:700px){
  .hom{
    display:none !important;
  }

  .hol{
    display: inline-flex !important;
  }
}